@import "~bulma/sass/base/minireset";

html,
body {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

.App {
  min-height: 100vh;

  .app-content {
    min-height: 100vh;

    .app-wrapper {
      &.is-tablet,
      &.is-mobile {
        margin: 0.75rem;
      }
    }
  }
}

* {
  box-sizing: border-box;
}

.focusable {
  &:focus {
    box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 5px rgba(17, 112, 207, 0.3),
      0px 0px 0px 4px #1170cf;
    outline: none;
  }
}
